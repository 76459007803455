
import { defineComponent } from "vue";
//import { ElMessage } from "element-plus";
import SAML from "@/components/SSO/SAML.vue";

export default defineComponent({
  name: "SSO-SAML",
  components: {
    SAML,
  },
  data() {
    return {
      service: "",
      anchor: {
        SAML: "SAML",
      },
      option: "",
    };
  },
  mounted() {
    this.option = this.$router.currentRoute.value.params.id as string;
    this.service = "SAML";
    // if(option){
    //   console.log(option);
    // }else{
    //   ElMessage.error("Oops, Something wrong.");
    //   setTimeout(() => {
    //     this.$router.push("/404");
    //   }, 2000);
    // }
    // let redirect = true;
    // for (const [key, value] of Object.entries(this.anchor)) {
    //   if (service == value) {
    //     redirect = false;
    //     this.service = key || value;
    //   }
    // }

    // // console.log(this);
    // // if (redirect) {
    //   ElMessage.error("Oops, Something wrong.");
    //   setTimeout(() => {
    //     this.$router.push("/404");
    //   }, 2000);
    // // }
  },
});
