import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import ElementPlus from "element-plus";

import "element-plus/dist/index.css";
import "./assets/style/components.scss";
import "./assets/style/style.scss";

// import firebase from "firebase/compat/app";
import firebase from "firebase/app";
// import "firebase/compat/auth";
// import "firebase/compat/firestore";
// import { Z_DEFAULT_STRATEGY } from 'zlib';
const configFirebase = {
  apiKey: "AIzaSyAE_lkz0jofbcDev5TrQI5FzevgWi8-VQA",
  authDomain: "auth.happily.ai",
  databaseURL: "https://people2-admin.firebaseio.com/",
  storageBucket: "people2-admin.appspot.com",
  projectId: "people2-admin",
  messagingSenderId: "561345191871",
};
firebase.initializeApp(configFirebase);

const app = createApp(App);

app.use(ElementPlus);
app.use(router);
app.mount("#app");
