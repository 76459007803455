import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "home" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SAML = _resolveComponent("SAML")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.service == 'SAML')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SAML, { option: _ctx.option }, null, 8, ["option"])
        ]))
      : _createCommentVNode("", true)
  ]))
}